<template>
  <div class="container">
    <el-input
      class="search-input"
      placeholder="Family_id/User_id/Phone/Email/..."
      prefix-icon="el-icon-search"
      @keyup.enter.native="searchClick"
      v-model="searchValue">
      <el-button slot="append" type="primary" @click="searchClick">Search</el-button>
    </el-input>
    <div class="userlist">
      <div class="head">
        <h4>User List</h4>
        <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick1">Export</el-button>
      </div>
      <div>
        <el-button slot="append" class="btn-ranges" @click="userRangeClick">{{userRangeText}}<i class="el-icon-caret-bottom"></i></el-button>
        <dataFilter
          v-for="(item,index) in userFilterText"
          :key="index"
          :item-data="item"
          :item-index="index"
          open-type="edit"
          :keyList="loginFilter"
          @delete="userListFilterDel"
          @apply="apply1"
        >
        </dataFilter>
        <dataFilter :item-data="userItem" :keyList="loginFilter" open-type="add" @apply="apply1"></dataFilter>
      </div>
      <div style="position:relative;">
        <datePicker :visable.sync="userPickerVisible" @applydate="applyDate"></datePicker>
      </div>
      <el-table
        style="width: 100%;height:auto;"
        max-height="400"
        :header-cell-style="{ background: '#F5F5F5' }"
        :data="userList"
        :default-sort = "{prop: 'time',order: 'descending'}"
        @sort-change="changeSort1"
        >
        <el-table-column
          prop="time"
          label="Log in time"
          sortable="custom"
          width="180">
        </el-table-column>
        <el-table-column
          prop="user_id"
          label="User_id"
          width="160">
          <template slot-scope="scope">
            <el-link @click="userIdClick(scope.row.user_id)" type="primary">{{scope.row.user_id}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="location"
          label="Location"
          width="300">
        </el-table-column>
        <el-table-column
          prop="age"
          label="Age"
          sortable="custom"
          width="80">
        </el-table-column>
        <el-table-column
          prop="group_id"
          label="Family_id"
          width="160">
          <template slot-scope="scope">
            <el-link @click="familyIdClick(scope.row.group_id)" type="primary">{{scope.row.group_id}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="group_size"
          label="Fam_Size"
          sortable="custom"
          width="110">
        </el-table-column>
        <el-table-column
          prop="roleText"
          label="Role"
          width="120">
        </el-table-column>
        <el-table-column
          prop="task_num"
          label="Event_Num"
          sortable="custom"
          width="120">
        </el-table-column>
        <el-table-column
          prop="vip"
          label="VIP"
          width="60">
        </el-table-column>
        <el-table-column
          prop="from"
          label="From">
        </el-table-column>
      </el-table>
      <pagination :onlineList="tableData1" @changepage="changePage1" @changesize="changeSize1"></pagination>
    </div>

    <div class="eventlist">
      <div class="head">
        <h4>Event List</h4>
        <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick2">Export</el-button>
      </div>
      <div>
        <el-button slot="append" class="btn-ranges" @click="eventRangeClick">{{eventRangeText}}<i class="el-icon-caret-bottom"></i></el-button>
        <dataFilter
          v-for="(item,index) in eventFilterText"
          :key="index"
          :item-data="item"
          :item-index="index"
          open-type="edit"
          :keyList="taskFilter"
          @delete="eventListFilterDel"
          @apply="apply2"
        >
        </dataFilter>
        <dataFilter :item-data="eventItem" :keyList="taskFilter" open-type="add" @apply="apply2"></dataFilter>
      </div>
      <div style="position:relative;">
        <datePicker :visable.sync="eventPickerVisible" @applydate="applyDate"></datePicker>
      </div>
      <el-table
        style="width: 100%;height:auto;"
        max-height="400"
        :header-cell-style="{ background: '#F5F5F5' }"
        :data="eventList"
        :default-sort = "{prop: 'create_time',order: 'descending'}"
        @sort-change="changeSort2"
        >
        <el-table-column
          prop="task_id"
          label="Event_id"
          width="160">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="Create_Time"
          sortable="custom"
          width="180">
        </el-table-column>
        <el-table-column
          prop="timezone"
          label="Time_Zone"
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          label="Event_Title"
          width="180">
        </el-table-column>
        <el-table-column
          prop="start_time"
          label="Event_Start"
          width="180">
        </el-table-column>
        <el-table-column
          prop="end_time"
          label="Event_End"
          width="180">
        </el-table-column>
        <el-table-column
          prop="remind"
          label="Reminder"
          width="120">
        </el-table-column>
        <el-table-column
          prop="repeat"
          label="Recurring"
          width="120">
        </el-table-column>
        <el-table-column
          prop="note"
          label="Note"
          width="180">
        </el-table-column>
        <el-table-column
          prop="user_id"
          label="User_id"
          width="160">
          <template slot-scope="scope">
            <el-link @click="userIdClick(scope.row.user_id)" type="primary">{{scope.row.user_id}}</el-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status"
          width="80">
        </el-table-column>
      </el-table>
      <pagination :onlineList="tableData2" @changepage="changePage2" @changesize="changeSize2"></pagination>
    </div>

    <div class="viplist">
      <div class="head">
        <h4>Vip List</h4>
        <el-button class="btn-export" type="text" icon="el-icon-download" @click="exportClick3">Export</el-button>
      </div>
      <div>
        <el-button slot="append" class="btn-ranges" @click="vipRangeClick">{{vipRangeText}}<i class="el-icon-caret-bottom"></i></el-button>
        <dataFilter
          v-for="(item,index) in vipFilterText"
          :key="index"
          :item-data="item"
          :item-index="index"
          open-type="edit"
          :keyList="vipFilter"
          @delete="vipListFilterDel"
          @apply="apply3"
        >
        </dataFilter>
        <dataFilter :item-data="vipItem" :keyList="vipFilter" open-type="add" @apply="apply3"></dataFilter>
      </div>
      <div style="position:relative;">
        <datePicker :visable.sync="vipPickerVisible" @applydate="applyDate"></datePicker>
      </div>
      <el-table
        style="width: 100%;height:auto;"
        max-height="400"
        :header-cell-style="{ background: '#F5F5F5' }"
        :data="vipList"
        :default-sort = "{prop: 'subscribe_time',order: 'descending'}"
        @sort-change="changeSort3"
        >
        <el-table-column
          prop="subscribe_time"
          label="Subscriber Time"
          sortable="custom">
        </el-table-column>
        <el-table-column
          prop="timezone"
          label="Time_Zone"
          width="180">
        </el-table-column>
        <el-table-column
          prop="subscribe_id"
          label="Subscriber id">
        </el-table-column>
        <el-table-column
          prop="plan"
          label="Plan">
        </el-table-column>
        <el-table-column
          prop="from"
          label="From">
        </el-table-column>
      </el-table>
      <pagination :onlineList="tableData3" @changepage="changePage3" @changesize="changeSize3"></pagination>
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/cubbily/DatePicker';
import DataFilter from '@/components/cubbily/DataFilter';
import Pagination from '@/components/cubbily/Pagination';
import {searchAll, searchLoginList, searchTaskList, searchVipList, exportReq} from '@/api/cubbily.js';
import moment, { isDate } from 'moment';

export default {
  name: 'UserBehavior',
  data () {
    return {
      searchValue: '',
      roles: ['Mom', 'Dad', 'Son/Daughter/Child', 'Grandparent', 'Partner/Spouse', 'Friend', 'Other'],
      notifyType: ['', 'mins', 'hours', 'days'],
      notifyType2: ['No', 'n minutes before', 'n hours before', 'n days before'],
      userList: [],
      userRangeText: 'Last 30 days',
      userFilterText: [],
      userStartTime: '',
      userEndTime: '',
      userItem: {
        keys: '1'
      },
      tableData1: {
        pageSize: 30,
        pageNum: 1,
        total: 0,
        order: '',
        sort: ''
      },
      eventList: [],
      eventRangeText: 'Last 30 days',
      eventFilterText: [],
      eventStartTime: '',
      eventEndTime: '',
      eventItem: {
        keys: '1'
      },
      tableData2: {
        pageSize: 30,
        pageNum: 1,
        total: 0,
        order: '',
        sort: ''
      },
      vipList: [],
      vipRangeText: 'Last 30 days',
      vipFilterText: [],
      vipStartTime: '',
      vipEndTime: '',
      vipItem: {
        keys: '1'
      },
      tableData3: {
        pageSize: 30,
        pageNum: 1,
        total: 0,
        order: '',
        sort: ''
      },
      userPickerVisible: false,
      eventPickerVisible: false,
      vipPickerVisible: false,
      loginVisible: false,
      taskVisible: false,
      vipVisible: false,
      keyFilter: '',
      dataParams: {},
      filterParams: {},
      eventFilterParams: {},
      vipFilterParams: {},

      loginFilter: [
        {
          value: 'user_id',
          label: 'User_id'
        }, {
          value: 'location',
          label: 'Location'
        }, {
          value: 'age',
          label: 'Age'
        }, {
          value: 'group_id',
          label: 'Family_id'
        }, {
          value: 'size',
          label: 'Fam_Size'
        },  {
          value: 'role',
          label: 'Role'
        }, {
          value: 'task_num',
          label: 'Fam_Event_Num'
        }, {
          value: 'vip',
          label: 'VIP'
        }
      ],
      taskFilter: [
        {
          value: 'task_id',
          label: 'Event_id'
        }, {
          value: 'name',
          label: 'Event_Title'
        }, {
          value: 'create_start',
          label: 'Event_Start'
        }, {
          value: 'create_end',
          label: 'Event_End'
        }, {
          value: 'reminder_1',
          label: 'Reminder1'
        }, {
          value: 'reminder_2',
          label: 'Reminder2'
        }, {
          value: 'recurring',
          label: 'Recurring'
        }, {
          value: 'note',
          label: 'Note'
        }, {
          value: 'user_id',
          label: 'User_id'
        }, {
          value: 'status',
          label: 'Status'
        }
      ],
      vipFilter: [
        {
          value: 'user_id',
          label: 'Subscriber id'
        }, {
          value: 'plan',
          label: 'Plan'
        }, {
          value: 'from',
          label: 'From'
        }
      ],
      operatorList: [
        {'=': 1 }, {'≠': 2 }, {'>': 3 }, {'<': 4 }, {'is one of': 5 }, {'exists': 6 }
      ],
      recurringList: ['No', 'Every Day', 'Every Week', 'Every Month', 'Every Year', 'Every other Day', 'Every other Week', 'Every other Month', 'Every other Year']
    };
  },
  components: {
    datePicker: DatePicker,
    dataFilter: DataFilter,
    pagination: Pagination
  },
  methods: {
    // 拿到子组件回传的参数
    apply1 (data) {
      console.log('回传的参数', data);
      if (data.isAddFilterKey) {
        this.userFilterText.push(data);
        console.log('add', this.userFilterText);
      } else {
        this.userFilterText.forEach((item) => {
          if (item.key == data.key) {
            item.operator = data.operator;
            item.value = data.value;
          }
        });
        console.log('reset', this.userFilterText);
      }

      let op = '', val = data.value;
      this.operatorList.forEach((item) => {
        if (Object.keys(item) == data.operator) {
          op = item[data.operator];
        }
      });
      if (data.key == 'vip') {val = val == 'Yes' ? 1 : 0;}
      if (data.key == 'role') {
        if (data.operator != 'is one of') {val = this.roles.indexOf(val) + 1;}
      }
      let reqData = Object.assign(this.filterParams, {
        [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
      });
      this.tableData1.pageNum = 1;
      this.getLoginList(reqData);
    },
    apply2 (data) {
      if (data.isAddFilterKey) {
        this.eventFilterText.push(data);
        console.log('add', this.eventFilterText);
      } else {
        this.eventFilterText.forEach((item) => {
          if (item.key == data.key) {
            item.operator = data.operator;
            item.value = data.value;
          }
        });
        console.log('reset', this.eventFilterText);
      }

      let op = '', val = data.value;
      this.operatorList.forEach((item) => {
        if (Object.keys(item) == data.operator) {
          op = item[data.operator];
        }
      });
      if (data.key == 'recurring' && this.recurringList.includes(val)) {val = this.recurringList.indexOf(val) + 1;}
      if (data.key == 'status') {val = val == 'Deleted' ? 2 : 1;}

      // todo
      let req = {};
      if (data.keyText == 'Reminder1' || data.keyText == 'Reminder2') {
        let notify_type = ['%', '%'], notify_before = ['%', '%'];
        if (this.eventFilterParams.notify_type) {
          notify_type = this.eventFilterParams.notify_type.split(' ')[1];
          notify_before = this.eventFilterParams.notify_before.split(' ')[1];
          if (notify_type.indexOf(',') != -1) {
            notify_type = notify_type.split(',');
            notify_before = notify_before.split(',');
          } else {
            notify_type = notify_type.split('');
            notify_before = notify_before.split('');
          }
        }
        let isOne = data.keyText == 'Reminder1';
        let typeIndex = this.notifyType2.indexOf(data.value);
        if (isOne) {
          notify_type[0] = typeIndex > -1 ? typeIndex : 1;
          notify_before[0] = data.number || 0;
          if (notify_type[1] == '%' && notify_before[1] == '%') {
            notify_type = notify_type.toString().replace(/,/g, '');
            notify_before = notify_before.toString().replace(/,/g, '');
          }
        } else {
          notify_type[1] = typeIndex > -1 ? typeIndex : 1;
          notify_before[1] = data.number || 0;
          notify_type = notify_type.toString();
          notify_before = notify_before.toString();
        }
        let type, before;
        if (data.operator == 'exists') {
          type = `${op} ${isOne ? 1 : 2}`;
          before = `${op} ${isOne ? 1 : 2}`;
        } else if (data.operator != 'exists') {
          let isNo = data.operator == '=' && data.val != 'No';
          type = isNo ? `7 ${isOne ? 1 : 2}` : `${op} ${notify_type}`;
          before = isNo ? `7 ${isOne ? 1 : 2}` : `${op} ${notify_before}`;
        }
        req = {
          'notify_type': type,
          'notify_before': before
        };
      } else {
        req = {
          [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
        };
      }
      this.tableData2.pageNum = 1;
      let reqData = Object.assign(this.eventFilterParams, req);
      this.getTaskList(reqData);
      console.error(this.eventFilterParams);
      console.error(reqData);
    },
    apply3 (data) {
      if (data.isAddFilterKey) {
        this.vipFilterText.push(data);
        console.log('add', this.vipFilterText);
      } else {
        this.vipFilterText.forEach((item) => {
          if (item.key == data.key) {
            item.operator = data.operator;
            item.value = data.value;
          }
        });
        console.log('reset', this.vipFilterText);
      }

      let op = '', val = data.value;
      this.operatorList.forEach((item) => {
        if (Object.keys(item) == data.operator) {
          op = item[data.operator];
        }
      });
      if (data.key == 'status') {val = val == 'Deleted' ? 2 : 1;}
      let reqData = Object.assign(this.vipFilterParams, {
        [data.key]: data.operator != 'exists' ? `${op} ${val}` : `${op}`
      });
      this.tableData3.pageNum = 1;
      this.getVipList(reqData);
    },

    // add filter 删除某个条件
    userListFilterDel (i) {
      let key = this.userFilterText[i].key;
      let obj = this.filterParams;
      delete (obj[key]);
      this.userFilterText.splice(i, 1);
      this.tableData1.pageNum = 1;
      this.getLoginList(this.filterParams);
    },
    eventListFilterDel (i) {
      let key = this.eventFilterText[i].key;
      let obj = this.eventFilterParams;
      let notReminder = true;
      this.eventFilterText.splice(i, 1);
      console.log(this.eventFilterText);
      if (key == 'reminder_1' || key == 'reminder_2') {
        let op1 = obj.notify_type.split(' ')[0];
        let op2 = obj.notify_before.split(' ')[0];
        let notify_type = obj.notify_type.split(' ')[1];
        let notify_before = obj.notify_before.split(' ')[1];
        if (notify_type.indexOf(',') != -1) {
          notify_type = notify_type.split(',');
          notify_before = notify_before.split(',');
        } else {
          notify_type = notify_type.split('');
          notify_before = notify_before.split('');
        }
        if (key == 'reminder_1') {
          notify_type[0] = '%';
          notify_before[0] = '%';
          notify_type = notify_type.toString();
          notify_before = notify_before.toString();
        } else {
          notify_type[1] = '%';
          notify_before[1] = '%';
          notify_type = notify_type.toString().replace(/,/g, '');
          notify_before = notify_before.toString().replace(/,/g, '');
        }
        if (this.eventFilterText.length) {
          this.eventFilterText.forEach((item) => {
            if (item.key == 'reminder_1' || item.key == 'reminder_2') {
              notReminder = false;
            }
          });
        }
        if (!this.eventFilterText.length || notReminder) {
          delete obj['notify_type'];
          delete obj['notify_before'];
        } else {
          this.eventFilterParams.notify_type = `${op1} ${notify_type}`;
          this.eventFilterParams.notify_before = `${op2} ${notify_before}`;
        }
      } else {
        delete (obj[key]);
      }
      this.tableData2.pageNum = 1;
      this.getTaskList(this.eventFilterParams);
    },
    vipListFilterDel (i) {
      let key = this.vipFilterText[i].key;
      let obj = this.vipFilterParams;
      delete (obj[key]);
      this.vipFilterText.splice(i, 1);
      this.tableData3.pageNum = 1;
      this.getVipList(this.vipFilterParams);
    },
    // close
    closeModal (e) {
      console.log(e);
    },

    // 登录列表
    getLoginList (params) {
      let data = {
        start_time: this.userStartTime,
        end_time: this.userEndTime,
        order: this.tableData1.order,
        sort: this.tableData1.sort,
        page: this.tableData1.pageNum,
        limit: this.tableData1.pageSize,
        ...params
      };
      console.log('登录列表data:', data);
      searchLoginList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          if (params && params.start_time) {this.userStartTime = params.start_time;}
          let list = res.data.login_list;
          list.forEach((item) => {
            // item.time = moment(item.time).format('YYYY-MM-DD HH:mm:ss');
            // item.age = (item.age < 0 || item.age > 200) ?  'Not Set' : item.age;
            item.vip = item.vip ? 'Yes' : 'No';
            item.roleText = item.role != 0 ? this.roles[item.role - 1] : 'Other';
          });
          this.userList = list;
          this.tableData1.total = res.data.total;
          this.exportReqData1 = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getLoginList：', error);
          this.$message.error(error.error_msg);
        });
    },
    // 日程列表
    getTaskList (params) {
      let data = {
        start_time: this.eventStartTime,
        end_time: this.eventEndTime,
        order: this.tableData2.order,
        sort: this.tableData2.sort,
        page: this.tableData2.pageNum,
        limit: this.tableData2.pageSize,
        ...params
      };
      console.log('日程列表data:', data);
      searchTaskList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          if (params && params.start_time) {this.eventStartTime = params.start_time;}
          let list = res.data.task_list;
          list.forEach((item) => {
            // item.create_time = moment(item.create_time).format('YYYY-MM-DD HH:mm:ss');
            // item.start_time = moment(item.start_time).format('YYYY-MM-DD HH:mm:ss');
            // item.end_time = moment(item.end_time).format('YYYY-MM-DD HH:mm:ss');

            // notify_type  0或空:不提醒 1:分钟，2:小时，3:天，数组类型
            // notify_before  日程提醒的时间
            // item.notify_type = item.notify_type.split(',');
            // item.notify_before = item.notify_before.split(',');
            // let text = [];
            // if (item.notify_type.length) {
            //   for (let i in item.notify_type) {
            //     let str = '';
            //     if (item.notify_before[i] == 0) {
            //       str = item.notify_type[i] == 0 ? 'No' : 'At the time of event';
            //     } else {
            //       let after = this.notifyType[item.notify_type[i]];
            //       str = `${item.notify_before[i]} ${after} before`;
            //     }
            //     text.push(str);
            //   }
            //   item.reminder1 = text[0] || 'No';
            //   item.reminder2 = text[1] || 'No';
            // } else {
            //   item.reminder1 = 'No';
            //   item.reminder2 = 'No';
            // }
            // let i = this.recurringList.indexOf(item.repeat);
            // item.repeat = i > -1 ? this.recurringList[i] : '-';

            item.status = item.status == 2 ? 'Deleted' : '-';
            item.note = item.note ? item.note : '-';
          });
          this.eventList = list;
          this.tableData2.total = res.data.total;
          this.exportReqData2 = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getTaskList：', error);
          this.$message.error(error.error_msg);
        });
    },
    // vip列表
    getVipList (params) {
      let data = {
        start_time: this.vipStartTime,
        end_time: this.vipEndTime,
        order: this.tableData3.order,
        sort: this.tableData3.sort,
        page: this.tableData3.pageNum,
        limit: this.tableData3.pageSize,
        ...params
      };
      console.log('vip列表data:', data);
      searchVipList(data)
        .then((res) => {
          if (res.result != 1) {
            this.$message.error(res.error_msg);
            return;
          }
          if (params && params.start_time) {this.vipStartTime = params.start_time;}
          let list = res.data.vip_list;
          // list.forEach((item) => {
          //   item.time = moment(item.time).format('YYYY-MM-DD HH:mm:ss');
          // });
          this.vipList = list;
          this.tableData3.total = res.data.total;
          this.exportReqData3 = Object.assign(data, {limit: res.data.total});
        })
        .catch((error) => {
          console.log('getVipList：', error);
          this.$message.error(error.error_msg);
        });
    },

    // search点击
    searchClick () {
      console.log('searchClick:', this.searchValue);
      searchAll({
        platform_id: this.searchValue.replace(/\s+/g, '')
      })
        .then((res) => {
          if (res.result != 1) {
            this.$message.error('Not found');
            return;
          }
          let data = res.data;
          this.$router.push({
            name: data.type == 1 ? 'FamilyInfo' : 'UserInfo',
            params: { searchId: data.id }
          });
        })
        .catch((error) => {
          console.log('searchAll：', error);
          this.$message.error(error.error_msg);
        });
    },

    userIdClick (userId) {
      this.$router.push({
        name: 'UserInfo',
        params: { searchId: userId }
      });
    },

    familyIdClick (groupId) {
      this.$router.push({
        name: 'FamilyInfo',
        params: { searchId: groupId }
      });
    },

    // time ranger点击
    userRangeClick () {
      this.userPickerVisible = !this.userPickerVisible;
      this.dateFilter = 'loginPicker';
    },
    eventRangeClick () {
      this.eventPickerVisible = !this.eventPickerVisible;
      this.dateFilter = 'eventPicker';
    },
    vipRangeClick () {
      this.vipPickerVisible = !this.vipPickerVisible;
      this.dateFilter = 'vipPicker';
    },

    // add filter点击
    userListFilter (item) {
      if (item) {
        let itemData = item.split('  ');
        this.userItem = {
          keys: itemData[0],
          operator: itemData[1],
          values: itemData[2]
        };
      } else {
        this.userItem = {
          keys: '',
          operator: '',
          values: ''
        };
      }
      this.loginVisible = !this.loginVisible;
      this.keyFilter = 'loginFilter';
      if (this.loginVisible) {
        this.$refs['userFilter'].openModal(this.userItem);
      }
    },
    taskListFilter (item) {
      if (item) {
        let itemData = item.split('  ');
        this.eventItem = {
          keys: itemData[0],
          operator: itemData[1],
          values: itemData[2]
        };
      } else {
        this.eventItem = {
          keys: '',
          operator: '',
          values: ''
        };
      }
      this.taskVisible = !this.taskVisible;
      this.keyFilter = 'taskFilter';
      if (this.taskVisible) {
        this.$refs['taskFilter'].openModal(this.eventItem);
      }
    },
    vipListFilter (item) {
      if (item) {
        let itemData = item.split('  ');
        this.vipItem = {
          keys: itemData[0],
          operator: itemData[1],
          values: itemData[2]
        };
      } else {
        this.vipItem = {
          keys: '',
          operator: '',
          values: ''
        };
      }
      this.vipVisible = !this.vipVisible;
      this.keyFilter = 'vipFilter';
      if (this.vipVisible) {
        this.$refs['vipFilter'].openModal(this.vipItem);
      }
    },

    // apply点击
    applyFilter (data) {
      console.log('父组件applyFilter：', data);
      switch (this.keyFilter) {
      case 'loginFilter':
        this.userFilterText = data.filterValue;
        this.getLoginList(data.params);
        break;
      case 'taskFilter':
        this.eventFilterText = data.filterValue;
        this.getTaskList(data.params);
        break;
      case 'vipFilter':
        this.vipFilterText = data.filterValue;
        this.getVipList(data.params);
        break;
      default:
        break;
      }
      this.filterParams = data.params;
    },
    applyDate (data) {
      console.log('父组件applyDate：', data);
      switch (this.dateFilter) {
      case 'loginPicker':
        if (data.text) {this.userRangeText = data.text;}
        this.getLoginList(Object.assign(this.filterParams, data.params));
        break;
      case 'eventPicker':
        if (data.text) {this.eventRangeText = data.text;}
        this.getTaskList(Object.assign(this.eventFilterParams, data.params));
        break;
      case 'vipPicker':
        if (data.text) {this.vipRangeText = data.text;}
        this.getVipList(Object.assign(this.vipFilterParams, data.params));
        break;
      }
      this.dataParams = data.params;
    },
    exportClick1 () {
      exportReq({
        url: '/v2/search/login_export',
        fileName: 'userList',
        data: {
          ...this.exportReqData1
        }
      });
    },
    exportClick2 () {
      exportReq({
        url: '/v2/search/task_export',
        fileName: 'eventList',
        data: {
          ...this.exportReqData2
        }
      });
    },
    exportClick3 () {
      exportReq({
        url: '/v2/search/vip_export',
        fileName: 'vipList',
        data: {
          ...this.exportReqData3
        }
      });
    },
    changePage1 (current) {
      if (current) {
        this.tableData1.pageNum = current;
        this.getLoginList(this.filterParams);
      }
    },
    changeSize1 (size) {
      if (size) {
        this.tableData1.pageSize = size;
        this.tableData1.pageNum = 1;
        this.getLoginList(this.filterParams);
      }
    },
    changePage2 (current) {
      if (current) {
        this.tableData2.pageNum = current;
        this.getTaskList(this.eventFilterParams);
      }
    },
    changeSize2 (size) {
      if (size) {
        this.tableData2.pageSize = size;
        this.tableData2.pageNum = 1;
        this.getTaskList(this.eventFilterParams);
      }
    },
    changePage3 (current) {
      if (current) {
        this.tableData3.pageNum = current;
        this.getVipList(this.vipFilterParams);
      }
    },
    changeSize3 (size) {
      if (size) {
        this.tableData3.pageSize = size;
        this.tableData3.pageNum = 1;
        this.getVipList(this.vipFilterParams);
      }
    },
    // 排序的方法
    changeSort1 (data) {
      console.log(data); // column: {…} order: "ascending" prop: "date"
      // 支持传参login_ts登陆时间、birthday年龄、group_size成员数量、task_num创建日程数量
      let prop = data.prop == 'time' ? 'login_ts' : (data.prop == 'age' ? 'birthday' : data.prop);
      let sort = data.order == 'ascending' ? 1 : 0;
      this.tableData1.pageNum = 1;
      this.tableData1.order = prop;
      this.tableData1.sort = sort;
      this.getLoginList({});
    },
    changeSort2 (data) {
      console.log(data); // column: {…} order: "ascending" prop: "date"
      // 支持传参create_time日程创建时间
      let prop = data.prop;
      let sort = data.order == 'ascending' ? 1 : 0;
      this.tableData2.pageNum = 1;
      this.tableData2.order = prop;
      this.tableData2.sort = sort;
      this.getTaskList({});
    },
    changeSort3 (data) {
      console.log(data); // column: {…} order: "ascending" prop: "date"
      // 支持传参sub_time订阅时间
      let prop = data.prop == 'subscribe_time' ? 'sub_time' : data.prop;
      let sort = data.order == 'ascending' ? 1 : 0;
      this.tableData3.pageNum = 1;
      this.tableData3.order = prop;
      this.tableData3.sort = sort;
      this.getVipList({});
    }
  },

  beforeCreate () {},
  created () {
    let date = new Date();
    let timenow = new Date().getTime();
    let timeago = date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
    this.userStartTime = timeago;
    this.eventStartTime = timeago;
    this.vipStartTime = timeago;
    this.userEndTime = timenow;
    this.eventEndTime = timenow;
    this.vipEndTime = timenow;

    this.getLoginList({});
    this.getTaskList({});
    this.getVipList({});
  },
  beforeMount () {},
  mounted () {
    document.addEventListener('click', this.closeModal);
  },
  beforeDestroy () {
  },
  destroyed () {}
};
</script>
<style lang="scss">
.container {
  width: calc(100% - 24px);
  // height: calc(100vh - 50px - 12px - 24px);
  padding: 22px;
  box-sizing: border-box;
  margin: 12px;
  background: #fff;
  position: relative;
  .search-input{
    width: 480px;
  }
  .userlist,
  .eventlist,
  .viplist{
    margin-bottom: 30px;
    .head{
      display: flex;
      margin: 50px 0 10px;
      h4{
        // margin: 50px 0 10px;
        float: left;
        width: 50%;
      }
      .btn-export{
        /* margin: 50px 0 10px; */
        padding: 0;
        position: absolute;
        right: 24px;
      }
    }
  }
  .eventlist,
  .viplist{
    .head{
      margin: 90px 0 10px;
    }
  }
}
.el-table thead {
  color: #000;
}
.el-table th, .el-table td{
  padding: 6px 0;
}
.pagination{
  float: right;
}
.el-button--primary {
  color: #FFFFFF !important;
  background-color: #f74769 !important;
  border-color: #f74769 !important;
}
.btn-ranges{
  margin-bottom: 10px;
}
.btn-filter{
  text-decoration:underline
}
.btn-filter-item {
  display: inline-block;
  border: 1px solid #DCDFE6;
  margin: 0 10px;
  .el-button--text{
    color: #606266;
    padding: 0;
  }
}
</style>